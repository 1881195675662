import * as types from '../constants/actionTypes';

export const getLocations = (page, limit) => (dispatch, getState) => {
  const params = {
    page,
    limit
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.LOCATION_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/locations`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};
