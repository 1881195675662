import {
  Page404,
  Page500,
  Register
} from '../views/Pages';

import App from '../containers/App';
import Loadable from 'react-loadable';
import LoginPage from '../containers/pages/LoginPage.jsx';
import React from 'react';

function Loading() {
  return <div>Loading...</div>;
}

// const Breadcrumbs = Loadable({
//   loader: () => import('../views/Base/Breadcrumbs'),
//   loading: Loading,
// });

// const Cards = Loadable({
//   loader: () => import('../views/Base/Cards'),
//   loading: Loading,
// });

// const Carousels = Loadable({
//   loader: () => import('../views/Base/Carousels'),
//   loading: Loading,
// });

// const Collapses = Loadable({
//   loader: () => import('../views/Base/Collapses'),
//   loading: Loading,
// });

// const Dropdowns = Loadable({
//   loader: () => import('../views/Base/Dropdowns'),
//   loading: Loading,
// });

// const Forms = Loadable({
//   loader: () => import('../views/Base/Forms'),
//   loading: Loading,
// });

// const Jumbotrons = Loadable({
//   loader: () => import('../views/Base/Jumbotrons'),
//   loading: Loading,
// });

// const ListGroups = Loadable({
//   loader: () => import('../views/Base/ListGroups'),
//   loading: Loading,
// });

// const Navbars = Loadable({
//   loader: () => import('../views/Base/Navbars'),
//   loading: Loading,
// });

// const Navs = Loadable({
//   loader: () => import('../views/Base/Navs'),
//   loading: Loading,
// });

// const Paginations = Loadable({
//   loader: () => import('../views/Base/Paginations'),
//   loading: Loading,
// });

// const Popovers = Loadable({
//   loader: () => import('../views/Base/Popovers'),
//   loading: Loading,
// });

// const ProgressBar = Loadable({
//   loader: () => import('../views/Base/ProgressBar'),
//   loading: Loading,
// });

// const Switches = Loadable({
//   loader: () => import('../views/Base/Switches'),
//   loading: Loading,
// });

// const Tables = Loadable({
//   loader: () => import('../views/Base/Tables'),
//   loading: Loading,
// });

// const Tabs = Loadable({
//   loader: () => import('../views/Base/Tabs'),
//   loading: Loading,
// });

// const Tooltips = Loadable({
//   loader: () => import('../views/Base/Tooltips'),
//   loading: Loading,
// });

// const BrandButtons = Loadable({
//   loader: () => import('../views/Buttons/BrandButtons'),
//   loading: Loading,
// });

// const ButtonDropdowns = Loadable({
//   loader: () => import('../views/Buttons/ButtonDropdowns'),
//   loading: Loading,
// });

// const ButtonGroups = Loadable({
//   loader: () => import('../views/Buttons/ButtonGroups'),
//   loading: Loading,
// });

// const Buttons = Loadable({
//   loader: () => import('../views/Buttons/Buttons'),
//   loading: Loading,
// });

const Charts = Loadable({
  loader: () => import('../views/Charts'),
  loading: Loading,
});

const Dashboard = Loadable({
  loader: () => import('../views/Dashboard'),
  loading: Loading,
});

// const CoreUIIcons = Loadable({
//   loader: () => import('../views/Icons/CoreUIIcons'),
//   loading: Loading,
// });

// const Flags = Loadable({
//   loader: () => import('../views/Icons/Flags'),
//   loading: Loading,
// });

// const FontAwesome = Loadable({
//   loader: () => import('../views/Icons/FontAwesome'),
//   loading: Loading,
// });

// const SimpleLineIcons = Loadable({
//   loader: () => import('../views/Icons/SimpleLineIcons'),
//   loading: Loading,
// });

// const Alerts = Loadable({
//   loader: () => import('../views/Notifications/Alerts'),
//   loading: Loading,
// });

// const Badges = Loadable({
//   loader: () => import('../views/Notifications/Badges'),
//   loading: Loading,
// });

// const Modals = Loadable({
//   loader: () => import('../views/Notifications/Modals'),
//   loading: Loading,
// });

// const Colors = Loadable({
//   loader: () => import('../views/Theme/Colors'),
//   loading: Loading,
// });

// const Typography = Loadable({
//   loader: () => import('../views/Theme/Typography'),
//   loading: Loading,
// });

// const Widgets = Loadable({
//   loader: () => import('../views/Widgets/Widgets'),
//   loading: Loading,
// });

const UsersPage = Loadable({
  loader: () => import('../containers/pages/Users/UsersPage.jsx'),
  loading: Loading,
});

const User = Loadable({
  loader: () =>
    import('../containers/pages/Users/User.jsx'),
  loading: Loading,
});

const CountriesPage = Loadable({
  loader: () =>
    import('../containers/pages/Countries/CountriesPage'),
  loading: Loading,
});

const Country = Loadable({
  loader: () =>
    import('../containers/pages/Countries/Country'),
  loading: Loading,
});

const CategoriesPage = Loadable({
  loader: () =>
    import('../containers/pages/Categories/CategoriesPage'),
  loading: Loading,
});

const Category = Loadable({
  loader: () =>
    import('../containers/pages/Categories/Category'),
  loading: Loading,
});

const ServicesPage = Loadable({
  loader: () =>
    import('../containers/pages/Services/ServicesPage'),
  loading: Loading,
});

const Service = Loadable({
  loader: () =>
    import('../containers/pages/Services/Service'),
  loading: Loading,
});

const BrandsPage = Loadable({
  loader: () =>
    import('../containers/pages/Brands/BrandsPage'),
  loading: Loading,
});

const Brand = Loadable({
  loader: () =>
    import('../containers/pages/Brands/Brand'),
  loading: Loading,
});

const AttributeGroupsPage = Loadable({
  loader: () =>
    import('../containers/pages/AttributeGroups/AttributeGroupsPage'),
  loading: Loading,
});

const AttributeGroup = Loadable({
  loader: () =>
    import('../containers/pages/AttributeGroups/AttributeGroup'),
  loading: Loading,
});

const AttributesPage = Loadable({
  loader: () =>
    import('../containers/pages/Attributes/AttributesPage'),
  loading: Loading,
});

const Attribute = Loadable({
  loader: () =>
    import('../containers/pages/Attributes/Attribute'),
  loading: Loading,
});

const AttributeTermsPage = Loadable({
  loader: () =>
    import('../containers/pages/AttributeTerms/AttributeTermsPage'),
  loading: Loading,
});

const AttributeTerm = Loadable({
  loader: () =>
    import('../containers/pages/AttributeTerms/AttributeTerm'),
  loading: Loading,
});

const ProductAttributesPage = Loadable({
  loader: () =>
    import('../containers/pages/ProductAttributes/ProductAttributesPage'),
  loading: Loading,
});

const ProductAttribute = Loadable({
  loader: () =>
    import('../containers/pages/ProductAttributes/ProductAttribute'),
  loading: Loading,
});

const ProductsPage = Loadable({
  loader: () =>
    import('../containers/pages/Products/ProductsPage'),
  loading: Loading,
});

const Product = Loadable({
  loader: () =>
    import('../containers/pages/Products/Product'),
  loading: Loading,
});

const DownloadsPage = Loadable({
  loader: () =>
    import('../containers/pages/Downloads/DownloadsPage'),
  loading: Loading,
});

const Download = Loadable({
  loader: () =>
    import('../containers/pages/Downloads/Download'),
  loading: Loading,
});

// const CompaniesPage = Loadable({
//   loader: () =>
//     import('../containers/pages/Companies/CompaniesPage'),
//   loading: Loading,
// });

// const Company = Loadable({
//   loader: () =>
//     import('../containers/pages/Companies/Company'),
//   loading: Loading,
// });

// const ProductCompaniesPage = Loadable({
//   loader: () =>
//     import('../containers/pages/ProductCompanies/ProductCompaniesPage'),
//   loading: Loading,
// });

// const ProductCompany = Loadable({
//   loader: () =>
//     import('../containers/pages/ProductCompanies/ProductCompany'),
//   loading: Loading,
// });

const SettingsPage = Loadable({
  loader: () =>
    import('../containers/pages/Settings/SettingsPage'),
  loading: Loading,
});

const Setting = Loadable({
  loader: () =>
    import('../containers/pages/Settings/Setting'),
  loading: Loading,
});

const MessagesPage = Loadable({
  loader: () =>
    import('../containers/pages/Messages/MessagesPage'),
  loading: Loading,
});

const Message = Loadable({
  loader: () =>
    import('../containers/pages/Messages/Message'),
  loading: Loading,
});

const PagesPage = Loadable({
  loader: () =>
    import('../containers/pages/Pages/PagesPage'),
  loading: Loading,
});

const Page = Loadable({
  loader: () =>
    import('../containers/pages/Pages/Page'),
  loading: Loading,
});

const NotificationsPage = Loadable({
  loader: () =>
    import('../containers/pages/Notifications/NotificationsPage'),
  loading: Loading,
});

const ReportsPage = Loadable({
  loader: () =>
    import('../containers/pages/Reports/ReportsPage'),
  loading: Loading,
});

const Report = Loadable({
  loader: () =>
    import('../containers/pages/Reports/Report'),
  loading: Loading,
});

const ApikeysPage = Loadable({
  loader: () =>
    import('../containers/pages/Apikeys/ApikeysPage'),
  loading: Loading,
});

const Apikey = Loadable({
  loader: () =>
    import('../containers/pages/Apikeys/Apikey'),
  loading: Loading,
});

const InstallersPage = Loadable({
  loader: () =>
    import('../containers/pages/Installers/InstallersPage'),
  loading: Loading,
});

const Installer = Loadable({
  loader: () =>
    import('../containers/pages/Installers/Installer'),
  loading: Loading,
});

const InstallerProducts = Loadable({
  loader: () =>
    import('../containers/pages/Installers/InstallerProducts'),
  loading: Loading,
})

const InstallerAnalyticsPage = Loadable({
  loader: () =>
    import('../containers/pages/Installers/InstallerAnalyticsPage'),
  loading: Loading,
})

const InstallerPackagesPage = Loadable({
  loader: () =>
    import('../containers/pages/Installers/InstallerPackagesPage'),
  loading: Loading,
});

const InstallerProductPackage = Loadable({
  loader: () =>
    import('../containers/pages/Installers/InstallerProductPackage'),
  loading: Loading,
});

const SortInstallerPackagesPage = Loadable({
  loader: () =>
    import('../containers/pages/Installers/SortInstallerPackagesPage'),
  loading: Loading,
});

const PostcodesPage = Loadable({
  loader: () =>
    import('../containers/pages/Postcodes/PostcodesPage'),
  loading: Loading,
});

const Postcode = Loadable({
  loader: () =>
    import('../containers/pages/Postcodes/Postcode'),
  loading: Loading,
});

const PostcodeSearchesPage = Loadable({
  loader: () =>
    import('../containers/pages/PostcodeSearches/PostcodeSearchesPage'),
  loading: Loading,
});

const LeadsPage = Loadable({
  loader: () =>
    import('../containers/pages/Leads/LeadsPage'),
  loading: Loading,
});

const Lead = Loadable({
  loader: () =>
    import('../containers/pages/Leads/Lead'),
  loading: Loading,
});

const TestimonialsPage = Loadable({
  loader: () =>
    import('../containers/pages/Testimonials/TestimonialsPage'),
  loading: Loading,
});

const Testimonial = Loadable({
  loader: () =>
    import('../containers/pages/Testimonials/Testimonial'),
  loading: Loading,
});

const MediaLibrariesPage = Loadable({
  loader: () =>
    import('../containers/pages/MediaLibraries/MediaLibrariesPage'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () =>
    import('../containers/pages/Profile/Profile'),
  loading: Loading,
});

const AdvertisementsPage = Loadable({
  loader: () =>
    import('../containers/pages/Advertisements/AdvertisementsPage'),
  loading: Loading,
});

const Advertisement = Loadable({
  loader: () =>
    import('../containers/pages/Advertisements/Advertisement'),
  loading: Loading,
});

const BrandProductsPage = Loadable({
  loader: () =>
    import('../containers/pages/BrandProducts/BrandProductsPage'),
  loading: Loading,
});

const SortBrandProductsPage = Loadable({
  loader: () =>
    import('../containers/pages/BrandProducts/SortBrandProductsPage'),
  loading: Loading,
});

const ProductPackagesPage = Loadable({
  loader: () =>
    import('../containers/pages/ProductPackages/ProductPackagesPage'),
  loading: Loading,
});

const ProductPackage = Loadable({
  loader: () =>
    import('../containers/pages/ProductPackages/ProductPackage'),
  loading: Loading,
});

const ApplianceTypesPage = Loadable({
  loader: () =>
    import('../containers/pages/ApplianceTypes/ApplianceTypesPage'),
  loading: Loading,
});

const ApplianceType = Loadable({
  loader: () =>
    import('../containers/pages/ApplianceTypes/ApplianceType'),
  loading: Loading,
});

const ApplianceBrandsPage = Loadable({
  loader: () =>
    import('../containers/pages/ApplianceBrands/ApplianceBrandsPage'),
  loading: Loading,
});

const ApplianceBrand = Loadable({
  loader: () =>
    import('../containers/pages/ApplianceBrands/ApplianceBrand'),
  loading: Loading,
});

const AppliancesPage = Loadable({
  loader: () =>
    import('../containers/pages/Appliances/AppliancesPage'),
  loading: Loading,
});

const Appliance = Loadable({
  loader: () =>
    import('../containers/pages/Appliances/Appliance'),
  loading: Loading,
});

const ImportAppliancePage = Loadable({
  loader: () =>
    import('../containers/pages/Imports/ImportAppliancePage'),
  loading: Loading,
});

const routes = [
  {
    component: App,
    requireLogin: '/login',
    routes: [
      { path: '/', exact: true, name: 'Home', component: Dashboard },
      { path: '/dashboard', name: 'Dashboard', component: Dashboard, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/profile', name: 'Edit Profile', component: Profile },
      // { path: '/theme', exact: true, name: 'Theme', component: Colors },
      // { path: '/theme/colors', name: 'Colors', component: Colors },
      // { path: '/theme/typography', name: 'Typography', component: Typography },
      // { path: '/base', exact: true, name: 'Base', component: Cards },
      // { path: '/base/cards', name: 'Cards', component: Cards },
      // { path: '/base/forms', name: 'Forms', component: Forms },
      // { path: '/base/switches', name: 'Switches', component: Switches },
      // { path: '/base/tables', name: 'Tables', component: Tables },
      // { path: '/base/tabs', name: 'Tabs', component: Tabs },
      // { path: '/base/breadcrumbs', name: 'Breadcrumbs', component: Breadcrumbs },
      // { path: '/base/carousels', name: 'Carousel', component: Carousels },
      // { path: '/base/collapses', name: 'Collapse', component: Collapses },
      // { path: '/base/dropdowns', name: 'Dropdowns', component: Dropdowns },
      // { path: '/base/jumbotrons', name: 'Jumbotrons', component: Jumbotrons },
      // { path: '/base/list-groups', name: 'List Groups', component: ListGroups },
      // { path: '/base/navbars', name: 'Navbars', component: Navbars },
      // { path: '/base/navs', name: 'Navs', component: Navs },
      // { path: '/base/paginations', name: 'Paginations', component: Paginations },
      // { path: '/base/popovers', name: 'Popovers', component: Popovers },
      // { path: '/base/progress-bar', name: 'Progress Bar', component: ProgressBar },
      // { path: '/base/tooltips', name: 'Tooltips', component: Tooltips },
      // { path: '/buttons', exact: true, name: 'Buttons', component: Buttons },
      // { path: '/buttons/buttons', name: 'Buttons', component: Buttons },
      // { path: '/buttons/button-dropdowns', name: 'Button Dropdowns', component: ButtonDropdowns },
      // { path: '/buttons/button-groups', name: 'Button Groups', component: ButtonGroups },
      // { path: '/buttons/brand-buttons', name: 'Brand Buttons', component: BrandButtons },
      // { path: '/icons', exact: true, name: 'Icons', component: CoreUIIcons },
      // { path: '/icons/coreui-icons', name: 'CoreUI Icons', component: CoreUIIcons },
      // { path: '/icons/flags', name: 'Flags', component: Flags },
      // { path: '/icons/font-awesome', name: 'Font Awesome', component: FontAwesome },
      // { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
      // { path: '/notifications', exact: true, name: 'Notifications', component: Alerts },
      // { path: '/notifications/alerts', name: 'Alerts', component: Alerts },
      // { path: '/notifications/badges', name: 'Badges', component: Badges },
      // { path: '/notifications/modals', name: 'Modals', component: Modals },
      // { path: '/widgets', name: 'Widgets', component: Widgets },
      { path: '/charts', name: 'Charts', component: Charts },
      { path: '/users', exact: true,  name: 'Users', component: UsersPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/users/add', exact: true, name: 'Add user', component: User, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/users/:id', exact: true, name: 'User Details', component: User, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/countries', exact: true,  name: 'Countries', component: CountriesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/countries/add', exact: true, name: 'Add country', component: Country, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/countries/:id', exact: true, name: 'Country Details', component: Country, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/categories', exact: true,  name: 'Categories', component: CategoriesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/categories/add', exact: true, name: 'Add category', component: Category, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/categories/:id', exact: true, name: 'Category Details', component: Category, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/services', exact: true,  name: 'Services', component: ServicesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/services/add', exact: true, name: 'Add Service', component: Service, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/services/:id', exact: true, name: 'Service Details', component: Service, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/brands', exact: true,  name: 'Brands', component: BrandsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/brands/add', exact: true, name: 'Add brand', component: Brand, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/brands/:id', exact: true, name: 'Brand Details', component: Brand, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/attribute-groups', exact: true,  name: 'Attribute Groups', component: AttributeGroupsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/attribute-groups/add', exact: true, name: 'Add attribute group', component: AttributeGroup, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/attribute-groups/:id', exact: true, name: 'Attribute Group Details', component: AttributeGroup, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/attributes', exact: true,  name: 'Attributes', component: AttributesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/attributes/add', exact: true, name: 'Add attribute', component: Attribute, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/attributes/:id', exact: true, name: 'Attribute Details', component: Attribute, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/attributes/:attribute_id/attribute-terms', exact: true,  name: 'Attribute Terms', component: AttributeTermsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/attributes/:attribute_id/attribute-terms/add', exact: true, name: 'Add attribute term', component: AttributeTerm, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/attributes/:attribute_id/attribute-terms/:id', exact: true, name: 'Update Attribute term', component: AttributeTerm, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/products/:product_id/attributes', exact: true,  name: 'Product Attributes', component: ProductAttributesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/products/:product_id/attributes/:id', exact: true, name: 'Product Attribute Details', component: ProductAttribute, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/products', exact: true,  name: 'Products', component: ProductsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/products/add', exact: true, name: 'Add product', component: Product, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/products/:id', exact: true, name: 'Product Details', component: Product, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/downloads', exact: true,  name: 'Downloads', component: DownloadsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/downloads/add', exact: true, name: 'Add download', component: Download, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/downloads/:id', exact: true, name: 'Download Details', component: Download, roles: ["ADMIN"], requireLogin: '/login' },
      // { path: '/companies', exact: true,  name: 'Companies', component: CompaniesPage, roles: ["ADMIN"], requireLogin: '/login' },
      // { path: '/companies/add', exact: true, name: 'Add company', component: Company, roles: ["ADMIN"], requireLogin: '/login' },
      // { path: '/companies/:id', exact: true, name: 'Company Details', component: Company, roles: ["ADMIN"], requireLogin: '/login' },
      // { path: '/products/:product_id/companies', exact: true,  name: 'Product Companies', component: ProductCompaniesPage, roles: ["ADMIN"], requireLogin: '/login' },
      // { path: '/products/:product_id/companies/:id', exact: true, name: 'Product Company Details', component: ProductCompany, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/settings', exact: true,  name: 'Settings', component: SettingsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/settings/add', exact: true, name: 'Add setting', component: Setting, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/settings/:code', exact: true, name: 'Setting Details', component: Setting, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/messages', exact: true,  name: 'Messages', component: MessagesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/messages/add', exact: true, name: 'Add message', component: Message, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/messages/:id', exact: true, name: 'Message Details', component: Message, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/pages', exact: true,  name: 'Pages', component: PagesPage },
      { path: '/pages/add', exact: true, name: 'Add page', component: Page },
      { path: '/pages/:id', exact: true, name: 'Page Details', component: Page },
      { path: '/apikeys', exact: true,  name: 'Apikeys', component: ApikeysPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/apikeys/add', exact: true, name: 'Add API Key', component: Apikey, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/installers', exact: true,  name: 'Installers', component: InstallersPage },
      { path: '/installers/add', exact: true, name: 'Add Installer', component: Installer, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/installers/:id', exact: true, name: 'Installer Details', component: Installer },
      { path: '/installers/:id/products', exact: true, name: 'Installer Products', component: InstallerProducts },
      { path: '/installers/:id/analytics', exact: true, name: 'Installer Analytics', component: InstallerAnalyticsPage },
      { path: '/installers/:id/packages', exact: true, name: 'Installer Packages', component: InstallerPackagesPage },
      { path: '/installers/:id/sort-packages', exact: true, name: 'Sort Installer Packages', component: SortInstallerPackagesPage },
      { path: '/installers/:id/packages/add', exact: true, name: 'Add Installer Product Package', component: InstallerProductPackage },
      { path: '/postcodes', exact: true,  name: 'Postcodes', component: PostcodesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/postcodes/add', exact: true, name: 'Add Postcode', component: Postcode, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/postcodes/:id', exact: true, name: 'Postcode Details', component: Postcode, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/postcode-searches', exact: true,  name: 'Postcode Searches', component: PostcodeSearchesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/leads', exact: true,  name: 'Leads', component: LeadsPage },
      { path: '/leads/add', exact: true, name: 'Add Lead', component: Lead },
      { path: '/leads/:id', exact: true, name: 'Lead Details', component: Lead },
      { path: '/testimonials', exact: true,  name: 'Testimonials', component: TestimonialsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/testimonials/add', exact: true, name: 'Add Testimonial', component: Testimonial, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/testimonials/:id', exact: true, name: 'Testimonial Details', component: Testimonial, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/advertisements', exact: true,  name: 'Advertisements', component: AdvertisementsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/advertisements/add', exact: true, name: 'Add advertisement', component: Advertisement, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/advertisements/:id', exact: true, name: 'Advertisement Details', component: Advertisement, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/brands/:id/products', exact: true,  name: 'Brand Products', component: BrandProductsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/brands/:id/sort-products', exact: true,  name: 'Sort Brand Products', component: SortBrandProductsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/media-libraries', exact: true,  name: 'MediaLibraries', component: MediaLibrariesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/d-notifications', exact: true,  name: 'Notifications', component: NotificationsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/product-packages', exact: true,  name: 'Product Packages', component: ProductPackagesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/product-packages/add', exact: true, name: 'Add Product Package', component: ProductPackage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/product-packages/:id', exact: true, name: 'Product Package Details', component: ProductPackage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/appliance-types', exact: true,  name: 'Appliance Types', component: ApplianceTypesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/appliance-types/add', exact: true, name: 'Add Appliance Type', component: ApplianceType, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/appliance-types/:id', exact: true, name: 'Appliance Type Details', component: ApplianceType, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/appliance-brands', exact: true,  name: 'Appliance Brands', component: ApplianceBrandsPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/appliance-brands/add', exact: true, name: 'Add Appliance Brand', component: ApplianceBrand, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/appliance-brands/:id', exact: true, name: 'Appliance Brand Details', component: ApplianceBrand, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/appliances', exact: true,  name: 'Appliances', component: AppliancesPage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/appliances/add', exact: true, name: 'Add Appliance', component: Appliance, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/appliances/:id', exact: true, name: 'Appliance Details', component: Appliance, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/import-appliance', exact: true,  name: 'Import Appliance', component: ImportAppliancePage, roles: ["ADMIN"], requireLogin: '/login' },
      { path: '/reports', exact: true,  name: 'Reports', component: ReportsPage },
      { path: '/reports/add', exact: true, name: 'Add report', component: Report },
      { path: '/reports/:id', exact: true, name: 'Report Details', component: Report },
      { path: '/login', exact: true, name: 'User Details', component: LoginPage, ignoreTemplate: true },
      { path: '/register', exact: true, name: 'User Details', component: Register, ignoreTemplate: true },
      { path: '/404', exact: true, name: 'User Details', component: Page404, ignoreTemplate: true },
      { path: '/500', exact: true, name: 'User Details', component: Page500, ignoreTemplate: true },
      { name: 'Not found', component: Page404, ignoreTemplate: true }
    ]
  }
];

export default routes;
