import * as types from '../constants/actionTypes';

export const addAttributeGroup = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_GROUP_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeGroups`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getAttributeGroups = (page, limit) => (dispatch, getState) => {
  const params = {
    page,
    limit
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.ATTRIBUTE_GROUP_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/attributeGroups?orderBy=sort&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getAttributeGroupParents = () => (dispatch, getState) => {

  return dispatch({
    types: [types.API_REQUEST_SEND, types.ATTRIBUTE_GROUP_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/attributeGroups/parent`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        }
      }
    }
  })
};

export const getAttributeGroup = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_GROUP_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeGroups/${id}?include=attributes:limit(100|0):order(sort|desc),attribute_group`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const editAttributeGroup = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_GROUP_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeGroups/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const updateAttributeGroupsOrders = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_SET_ORDER, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeGroups/update/order`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteAttributeGroup = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_GROUP_DELETE_ITEM, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeGroups/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});
