import * as types from '../constants/actionTypes';

export const addAttributeTerm = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_TERM_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeTerms`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getAttributeTerms = (id, page, limit) => (dispatch, getState) => {
  const params = {
    page,
    limit
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.ATTRIBUTE_TERM_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/attributeTerms?search=${id}&include=attribute&orderBy=sort&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getAttributeTerm = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_TERM_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeTerms/${id}?include=attribute`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const editAttributeTerm = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_TERM_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeTerms/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const updateAttributeTermOrders = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_SET_ORDER, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeTerms/update/order`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteAttributeTerm = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_TERM_DELETE_ITEM, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributeTerms/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});
