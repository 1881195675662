import * as types from '../constants/actionTypes';
import _ from 'lodash';

export const addBrand = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.BRAND_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/brands`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getBrands = (page, limit, include = null, parent = null, category = null) => (dispatch, getState) => {
  const params = {
    page,
    limit,
    include,
    category
  };

  if (parent >= 0 && parent !== 'null' && parent !== null) {
    _.assign(params, {search: `parent:${parent}`});
  }

  return dispatch({
    types: [types.API_REQUEST_SEND, types.BRAND_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/brands?orderBy=sort;id&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getBrandsSelect = () => (dispatch, getState) => {

  return dispatch({
    types: [types.API_REQUEST_SEND, types.BRAND_SET_SELECT, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/brands?orderBy=sort;id&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        }
      }
    }
  })
};

export const getChildBrands = (id) => (dispatch, getState) => {

  return dispatch({
    types: [types.API_REQUEST_SEND, types.CHILDBRAND_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/brands?search=parent:${id}&orderBy=sort;id&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
      }
    }
  })
};

export const getOnlyParentBrands = (category = null) => (dispatch, getState) => {
  const params = {
    category
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.BRAND_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/brands?search=parent:0&orderBy=sort&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getBrand = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.BRAND_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/brands/${id}?include=categories`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const editBrand = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.BRAND_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/brands/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteBrand = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.BRAND_DELETE_ITEM, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/brands/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});

export const updateBrandOrders = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.BRAND_SET_ORDER, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/brands/update/order`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getParentBrandProducts = (id, include = null) => (dispatch, getState) => {
  const params = {
    include
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.BRAND_SET_SHOW, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/brands/${id}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};
