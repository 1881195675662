import auth from './auth';
import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import users from './users';
import countries from './countries';
import categories from './categories';
import services from './services';
import brands from './brands';
import attributeGroups from './attributeGroups';
import attributes from './attributes';
import attributeTerms from './attributeTerms';
import locations from './locations';
import settings from './settings';
import messages from './messages';
import pages from './pages';
import notifications from './notifications';
import reports from './reports';
import products from './products';
import downloads from './downloads';
import companies from './companies';
import productAttributes from './productAttributes';
// import productCompanies from './productCompanies';
import apikeys from './apikeys';
import installers from './installers';
import postcodes from './postcodes';
import leads from './leads';
import images from './images';
import testimonials from './testimonials';
import postcodeSearches from './postcodeSearches';
import advertisements from './advertisements';
import brandProducts from './brandProducts';
import productPackages from './productPackages';
import applianceTypes from './applianceTypes';
import applianceBrands from './applianceBrands';
import appliances from './appliances';

const rootReducer = combineReducers({
  routing,
  auth,
  users,
  countries,
  categories,
  services,
  brands,
  attributeGroups,
  attributes,
  attributeTerms,
  locations,
  settings,
  messages,
  pages,
  notifications,
  reports,
  products,
  downloads,
  companies,
  productAttributes,
  // productCompanies,
  apikeys,
  installers,
  postcodes,
  leads,
  images,
  testimonials,
  postcodeSearches,
  advertisements,
  brandProducts,
  productPackages,
  applianceTypes,
  applianceBrands,
  appliances,
});

export default rootReducer;
