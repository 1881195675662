import * as types from '../constants/actionTypes';

const initialState = {
  schema: {},
  list: null,
  show: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SETTING_SET_BROWSE:
      const newList = {
        ...state.show,
        list: action.payload.data,
        schema: action.schema,
      };
      state = newList;
      return state;
    case types.SETTING_SET_SHOW:
      state = {
        ...state.show,
        list: action.payload.data,
        schema: action.schema,
      };
      return state;
    case types.SETTING_DELETE_ITEM:
      const data = Object.keys(state.list)
        .reduce((obj, key) => {
          if (key !== action.code) {
            obj[key] = state.list[key];
          }
          return obj;
        }, {});
      state = {
        show: state.show,
        schema: state.schema,
        list: data,
      };
      return state;
    default:
      return state;
  }
}
