export const API_REQUEST_SEND = 'API_REQUEST_SEND';
export const API_REQUEST_ERROR = 'API_REQUEST_ERROR';
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS';
//auth
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_DISCARD_TOKEN = 'AUTH_DISCARD_TOKEN';
//users
export const USER_SET_BROWSE = 'USER_SET_BROWSE';
export const USER_SET_SHOW = 'USER_SET_SHOW';
export const USER_DELETE_ITEM = 'USER_DELETE_ITEM';
//countries
export const COUNTRY_SET_BROWSE = 'COUNTRY_SET_BROWSE';
export const COUNTRY_SET_SHOW = 'COUNTRY_SET_SHOW';
export const COUNTRY_DELETE_ITEM = 'COUNTRY_DELETE_ITEM';
//categories
export const CATEGORY_SET_BROWSE = 'CATEGORY_SET_BROWSE';
export const CATEGORY_SET_SHOW = 'CATEGORY_SET_SHOW';
export const CATEGORY_DELETE_ITEM = 'CATEGORY_DELETE_ITEM';
//images
export const IMAGE_SET_BROWSE = 'IMAGE_SET_BROWSE';
export const IMAGE_SET_SHOW = 'IMAGE_SET_SHOW';
export const IMAGE_DELETE_ITEM = 'IMAGE_DELETE_ITEM';
export const IMAGE_SET_ORDER = 'IMAGE_SET_ORDER';
//services
export const SERVICE_SET_BROWSE = 'SERVICE_SET_BROWSE';
export const SERVICE_SET_SHOW = 'SERVICE_SET_SHOW';
export const SERVICE_DELETE_ITEM = 'SERVICE_DELETE_ITEM';
//brands
export const BRAND_SET_BROWSE = 'BRAND_SET_BROWSE';
export const BRAND_SET_SHOW = 'BRAND_SET_SHOW';
export const BRAND_DELETE_ITEM = 'BRAND_DELETE_ITEM';
export const BRAND_SET_ORDER = 'BRAND_SET_ORDER';
export const CHILDBRAND_SET_BROWSE = 'CHILDBRAND_SET_BROWSE';
export const BRAND_SET_SELECT = 'BRAND_SET_SELECT';
export const BRAND_PRODUCT_SET_BROWSE = 'BRAND_PRODUCT_SET_BROWSE';
//attribute groups
export const ATTRIBUTE_GROUP_SET_BROWSE = 'ATTRIBUTE_GROUP_SET_BROWSE';
export const ATTRIBUTE_GROUP_SET_SHOW = 'ATTRIBUTE_GROUP_SET_SHOW';
export const ATTRIBUTE_GROUP_DELETE_ITEM = 'ATTRIBUTE_GROUP_DELETE_ITEM';
//attributes
export const ATTRIBUTE_SET_BROWSE = 'ATTRIBUTE_SET_BROWSE';
export const ATTRIBUTE_SET_SHOW = 'ATTRIBUTE_SET_SHOW';
export const ATTRIBUTE_DELETE_ITEM = 'ATTRIBUTE_DELETE_ITEM';
export const ATTRIBUTE_SET_BROWSE_GROUP = 'ATTRIBUTE_SET_BROWSE_GROUP';
export const ATTRIBUTE_SET_BROWSE_GROUP_PARENT = 'ATTRIBUTE_SET_BROWSE_GROUP_PARENT';
export const ATTRIBUTE_SET_ORDER = 'ATTRIBUTE_SET_ORDER';
export const PRODUCT_ATTRIBUTE_SET_BROWSE = 'PRODUCT_ATTRIBUTE_SET_BROWSE';
export const PRODUCT_ATTRIBUTE_SET_SHOW = 'PRODUCT_ATTRIBUTE_SET_SHOW';
export const PRODUCT_ATTRIBUTE_DELETE_ITEM = 'PRODUCT_ATTRIBUTE_DELETE_ITEM';
//attribute terms
export const ATTRIBUTE_TERM_SET_BROWSE = 'ATTRIBUTE_TERM_SET_BROWSE';
export const ATTRIBUTE_TERM_SET_SHOW = 'ATTRIBUTE_TERM_SET_SHOW';
export const ATTRIBUTE_TERM_DELETE_ITEM = 'ATTRIBUTE_TERM_DELETE_ITEM';
//products
export const PRODUCT_SET_BROWSE = 'PRODUCT_SET_BROWSE';
export const PRODUCT_SET_SHOW = 'PRODUCT_SET_SHOW';
export const PRODUCT_SET_SHOW_MODAL = 'PRODUCT_SET_SHOW_MODAL';
export const PRODUCT_DELETE_ITEM = 'PRODUCT_DELETE_ITEM';
export const PRODUCT_SET_ORDER = 'PRODUCT_SET_ORDER';
//downloads
export const DOWNLOAD_SET_BROWSE = 'DOWNLOAD_SET_BROWSE';
export const DOWNLOAD_SET_SHOW = 'DOWNLOAD_SET_SHOW';
export const DOWNLOAD_DELETE_ITEM = 'DOWNLOAD_DELETE_ITEM';
//companies
export const COMPANY_SET_BROWSE = 'COMPANY_SET_BROWSE';
export const COMPANY_SET_SHOW = 'COMPANY_SET_SHOW';
export const COMPANY_DELETE_ITEM = 'COMPANY_DELETE_ITEM';
export const PRODUCT_COMPANY_SET_BROWSE = 'PRODUCT_COMPANY_SET_BROWSE';
export const PRODUCT_COMPANY_SET_SHOW = 'PRODUCT_COMPANY_SET_SHOW';
export const PRODUCT_COMPANY_DELETE_ITEM = 'PRODUCT_COMPANY_DELETE_ITEM';
//locations
export const LOCATION_SET_BROWSE = 'LOCATION_SET_BROWSE';
//discourses
export const DISCOURSE_SET_BROWSE = 'DISCOURSE_SET_BROWSE';
export const DISCOURSE_SET_SHOW = 'DISCOURSE_SET_SHOW';
export const DISCOURSE_DELETE_ITEM = 'DISCOURSE_DELETE_ITEM';
//sess_requests
export const SESS_REQUEST_SET_BROWSE = 'SESS_REQUEST_SET_BROWSE';
export const SESS_REQUEST_SET_SHOW = 'SESS_REQUEST_SET_SHOW';
export const SESS_REQUEST_DELETE_ITEM = 'SESS_REQUEST_DELETE_ITEM';
//feedbacks
export const FEEDBACK_SET_BROWSE = 'FEEDBACK_SET_BROWSE';
export const FEEDBACK_SET_SHOW = 'FEEDBACK_SET_SHOW';
export const FEEDBACK_DELETE_ITEM = 'FEEDBACK_DELETE_ITEM';
//settings
export const SETTING_SET_BROWSE = 'SETTING_SET_BROWSE';
export const SETTING_SET_SHOW = 'SETTING_SET_SHOW';
export const SETTING_DELETE_ITEM = 'SETTING_DELETE_ITEM';
//messages
export const MESSAGE_SET_BROWSE = 'MESSAGE_SET_BROWSE';
export const MESSAGE_SET_SHOW = 'MESSAGE_SET_SHOW';
export const MESSAGE_DELETE_ITEM = 'MESSAGE_DELETE_ITEM';
//pages
export const PAGE_SET_BROWSE = 'PAGE_SET_BROWSE';
export const PAGE_SET_SHOW = 'PAGE_SET_SHOW';
export const PAGE_DELETE_ITEM = 'PAGE_DELETE_ITEM';
//notifications
export const NOTIFICATION_SET_BROWSE = 'NOTIFICATION_SET_BROWSE';
export const NOTIFICATION_SET_SHOW = 'NOTIFICATION_SET_SHOW';
export const NOTIFICATION_DELETE_ITEM = 'NOTIFICATION_DELETE_ITEM';
//reports
export const REPORT_SET_BROWSE = 'REPORT_SET_BROWSE';
export const REPORT_SET_SHOW = 'REPORT_SET_SHOW';
export const REPORT_DELETE_ITEM = 'REPORT_DELETE_ITEM';
//payments
export const PAYMENT_SET_BROWSE = 'PAYMENT_SET_BROWSE';
export const PAYMENT_SET_SHOW = 'PAYMENT_SET_SHOW';
export const PAYMENT_DELETE_ITEM = 'PAYMENT_DELETE_ITEM';

//apiKeys
export const APIKEY_SET_BROWSE = 'APIKEY_SET_BROWSE';
export const APIKEY_SET_SHOW = 'APIKEY_SET_SHOW';
//installers
export const INSTALLER_SET_BROWSE = 'INSTALLER_SET_BROWSE';
export const INSTALLER_SET_SHOW = 'INSTALLER_SET_SHOW';
export const INSTALLER_DELETE_ITEM = 'INSTALLER_DELETE_ITEM';
//postcodes
export const POSTCODE_SET_BROWSE = 'POSTCODE_SET_BROWSE';
export const POSTCODE_SET_SHOW = 'POSTCODE_SET_SHOW';
export const POSTCODE_DELETE_ITEM = 'POSTCODE_DELETE_ITEM';
//postcode searches
export const POSTCODE_SEARCH_SET_BROWSE = 'POSTCODE_SEARCH_SET_BROWSE';
export const POSTCODE_SEARCH_SET_SHOW = 'POSTCODE_SEARCH_SET_SHOW';
export const POSTCODE_SEARCH_DELETE_ITEM = 'POSTCODE_SEARCH_DELETE_ITEM';
//leads
export const LEAD_SET_BROWSE = 'LEAD_SET_BROWSE';
export const LEAD_SET_SHOW = 'LEAD_SET_SHOW';
export const LEAD_DELETE_ITEM = 'LEAD_DELETE_ITEM';
//testimonials
export const TESTIMONIAL_SET_BROWSE = 'TESTIMONIAL_SET_BROWSE';
export const TESTIMONIAL_SET_SHOW = 'TESTIMONIAL_SET_SHOW';
export const TESTIMONIAL_DELETE_ITEM = 'TESTIMONIAL_DELETE_ITEM';
//advertisements
export const ADVERTISEMENT_SET_BROWSE = 'ADVERTISEMENT_SET_BROWSE';
export const ADVERTISEMENT_SET_SHOW = 'ADVERTISEMENT_SET_SHOW';
export const ADVERTISEMENT_DELETE_ITEM = 'ADVERTISEMENT_DELETE_ITEM';
//productPackages
export const PRODUCT_PACKAGE_SET_BROWSE = 'PRODUCT_PACKAGE_SET_BROWSE';
export const PRODUCT_PACKAGE_SET_SHOW = 'PRODUCT_PACKAGE_SET_SHOW';
export const PRODUCT_PACKAGE_DELETE_ITEM = 'PRODUCT_PACKAGE_DELETE_ITEM';
export const PRODUCT_PACKAGE_SET_ORDER = 'PRODUCT_PACKAGE_SET_ORDER';
//applianceTypes
export const APPLIANCE_TYPE_SET_BROWSE = 'APPLIANCE_TYPE_SET_BROWSE';
export const APPLIANCE_TYPE_SET_SHOW = 'APPLIANCE_TYPE_SET_SHOW';
export const APPLIANCE_TYPE_DELETE_ITEM = 'APPLIANCE_TYPE_DELETE_ITEM';
//applianceBrands
export const APPLIANCE_BRAND_SET_BROWSE = 'APPLIANCE_BRAND_SET_BROWSE';
export const APPLIANCE_BRAND_SET_SHOW = 'APPLIANCE_BRAND_SET_SHOW';
export const APPLIANCE_BRAND_DELETE_ITEM = 'APPLIANCE_BRAND_DELETE_ITEM';
//appliances
export const APPLIANCE_SET_BROWSE = 'APPLIANCE_SET_BROWSE';
export const APPLIANCE_SET_SHOW = 'APPLIANCE_SET_SHOW';
export const APPLIANCE_DELETE_ITEM = 'APPLIANCE_DELETE_ITEM';
//Imports
export const IMPORT_APPLIANCE_SET_BROWSE = 'IMPORT_APPLIANCE_SET_BROWSE';
