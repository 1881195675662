import * as types from '../constants/actionTypes';

export const addInstaller = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.INSTALLER_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/installers`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getInstallers = (page, limit, search = null) => (dispatch, getState) => {
  const params = {
    page,
    limit,
    search
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.INSTALLER_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/installers?include=user&orderBy=id&sortedBy=desc&searchJoin=and`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getInstaller = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.INSTALLER_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/installers/${id}?include=user,galleries,services,brands,brands.categories`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const getInstallerProducts = (id, brand_id, category_id) => (dispatch, getState) => {
  const params = {
    brand_id,
    category_id
  };
  return dispatch({
    types: [types.API_REQUEST_SEND, types.PRODUCT_SET_SHOW, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/installers/${id}/products`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getInstallerTrackings = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.INSTALLER_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/installers/${id}?include=trackings`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const getInstallerCategoryBrands = (id, brand_id, category_id) => (dispatch, getState) => {
  const params = {
    brand_id,
    category_id
  };
  return dispatch({
    types: [types.API_REQUEST_SEND, types.PRODUCT_SET_SHOW, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/installers/${id}/brands`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getInstallerBrands = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.PRODUCT_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/installers/${id}/getbrands?include=brand`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const getInstallerBrandCategories = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.PRODUCT_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/installers/${id}/getbrandselecteds`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const editInstaller = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.INSTALLER_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/installers/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const editInstallerBrands = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.INSTALLER_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/installers/${id}/brands`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteInstaller = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.INSTALLER_DELETE_ITEM, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/installers/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});
