import * as types from '../constants/actionTypes';

export const getBrandProducts = (brand_id, category_id) => (dispatch, getState) => {
  const params = {
    brand_id,
    category_id
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.BRAND_PRODUCT_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/categories/${category_id}/products?brand_id=${brand_id}&include=brand,categories&orderBy=id&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        }
      }
    }
  })
};
