import * as types from '../constants/actionTypes';

export const addApikey = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.APIKEY_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/apikeys`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getApikeys = () => (dispatch, getState) => {

  return dispatch({
    types: [types.API_REQUEST_SEND, types.APIKEY_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/apikeys`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        }
      }
    }
  })
};

export const activateApikey = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.APIKEY_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/apikeys/activate`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deactivateApikey = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.APIKEY_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/apikeys/deactivate`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});
