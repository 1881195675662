import * as types from '../constants/actionTypes';

export const addProduct = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.PRODUCT_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/products`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getProducts = (page, limit, include = null, category_id = null, brand_id = null, status = 10, search = null, orderBy = 'id', sortedBy = 'desc') => (dispatch, getState) => {
  if (!include) {
    include = 'countries,brands,categories,attributes,images,downloads';
  }
  const params = {
    page,
    limit,
    category_id,
    brand_id,
    status,
    search,
    orderBy,
    sortedBy
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.PRODUCT_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/products?include=${include}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getProductsByBrandCategory = (brand_id, category_id) => (dispatch, getState) => {
  const params = {
    brand_id,
    category_id
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.PRODUCT_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/categories/${category_id}/products?search=brand_id:${brand_id}include=brand,categories&orderBy=id&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        }
      }
    }
  })
};

export const getProduct = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.PRODUCT_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/products/${id}?include=countries,brands,categories,attributes,images,downloads,product_attributes,attribute_terms`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const getProductModal = (id, include = null) => (dispatch, getState) => {
  if (!include) {
    include = 'brands,categories';
  }
  return dispatch({
    types: [types.API_REQUEST_SEND, types.PRODUCT_SET_SHOW_MODAL, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/products/${id}?include=${include}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        }
      }
    }
  });
};

export const editProduct = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.PRODUCT_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/products/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const quickEditProduct = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.PRODUCT_SET_SHOW_MODAL, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/products/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteProduct = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.PRODUCT_DELETE_ITEM, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/products/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});

export const updateProductOrders = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.PRODUCT_SET_ORDER, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/products/update/order`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});
