import * as types from '../constants/actionTypes';

export const addApplianceType = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.APPLIANCE_TYPE_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/applianceTypes`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getApplianceTypes = (page, limit, include = null) => (dispatch, getState) => {
  if (!include) {
    include = null;
  }
  const params = {
    page,
    limit
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.APPLIANCE_TYPE_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/applianceTypes?include=${include}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getApplianceType = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.APPLIANCE_TYPE_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/applianceTypes/${id}`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const editApplianceType = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.APPLIANCE_TYPE_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/applianceTypes/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteApplianceType = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.APPLIANCE_TYPE_DELETE_ITEM, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/applianceTypes/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});
