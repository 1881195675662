import * as types from '../constants/actionTypes';

const initialState = {
  schema: {},
  list: {
    data: [],
    links: {},
    meta: {}
  },
  show: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOCATION_SET_BROWSE:
      const newList = {
        ...state.show,
        list: action.payload.data,
        schema: action.schema,
      };
      state = newList;
      return state;
    default:
      return state;
  }
}
