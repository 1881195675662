import * as types from '../constants/actionTypes';

export const addImage = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.IMAGE_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/images`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`,
        'Content-Type': 'multipart/form-data'
      },
      data
    }
  }
});

export const getImages = (page, limit, search = null) => (dispatch, getState) => {
  const params = {
    page,
    limit,
    search
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.IMAGE_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/images?orderBy=id&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const editImage = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.IMAGE_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/images/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteImage = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.API_REQUEST_SUCCESS, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/images/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});

export const updateImageOrders = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.IMAGE_SET_ORDER, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/images/update/order`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});
