import * as types from '../constants/actionTypes';

export const importCsvAppliance = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.IMPORT_APPLIANCE_SET_BROWSE, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/import/appliances`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});
