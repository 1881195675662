import * as types from '../constants/actionTypes';

export const addCategory = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.CATEGORY_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/categories`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getCategories = (page, limit, include = null) => (dispatch, getState) => {
  if (!include) {
    include = null;
  }
  const params = {
    page,
    limit
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.CATEGORY_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/categories?include=${include}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getCategory = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.CATEGORY_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/categories/${id}`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const editCategory = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.CATEGORY_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/categories/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteCategory = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.CATEGORY_DELETE_ITEM, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/categories/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});
