import * as types from '../constants/actionTypes';

export const addAttribute = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributes`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getAttributes = (page, limit, attribute_group_id = null) => (dispatch, getState) => {
  const params = {
    page,
    limit,
    attribute_group_id
  };

  return dispatch({
    types: [types.API_REQUEST_SEND, types.ATTRIBUTE_SET_BROWSE, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/attributes?include=attribute_group&orderBy=sort&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        },
        params
      }
    }
  })
};

export const getAttribute = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributes/${id}?include=attribute_group`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  }
});

export const editAttribute = (id, data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_SET_SHOW, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributes/${id}`,
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const getAttributesByGroup = (id) => (dispatch, getState) => {

  return dispatch({
    types: [types.API_REQUEST_SEND, types.ATTRIBUTE_SET_BROWSE_GROUP, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/attributes/group/${id}?orderBy=sort&sortedBy=desc`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        }
      }
    }
  })
};

export const getAttributesByGroupParent = (id) => (dispatch, getState) => {

  return dispatch({
    types: [types.API_REQUEST_SEND, types.ATTRIBUTE_SET_BROWSE_GROUP_PARENT, types.API_REQUEST_ERROR],
    payload: {
      request: {
        url: `api/v1/attributes/groupParent/${id}`,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${getState().auth.user.token}`
        }
      }
    }
  })
};

export const updateAttributeOrders = (data) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_SET_ORDER, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributes/update/order`,
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      },
      data
    }
  }
});

export const deleteAttribute = (id) => (dispatch, getState) => dispatch({
  types: [types.API_REQUEST_SEND, types.ATTRIBUTE_DELETE_ITEM, types.API_REQUEST_ERROR],
  payload: {
    request: {
      url: `api/v1/attributes/${id}`,
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${getState().auth.user.token}`
      }
    }
  },
  id: id
});
