import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import { UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink } from 'reactstrap';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import avatar from '../../assets/img/brand/avatar.jpg';
import logo from '../../assets/img/brand/answers_logo.png';
import sygnet from '../../assets/img/brand/home-logo.png';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class Header extends Component {

  onClickLogout = async (e) => {
    e.preventDefault();

    await this.props.actions.logout();
  }

  render() {

    // eslint-disable-next-line
    const { children, auth, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 150, alt: 'Your Energy Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'Your Energy Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        { auth.isAdmin && (

          <Nav className="d-md-down-none" navbar>
            <NavItem className="px-3">
              <NavLink tag={Link} to="/dashboard">Dashboard</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink tag={Link} to="/users">Users</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink tag={Link} to="/settings">Settings</NavLink>
            </NavItem>
          </Nav>
        )}
        <Nav className="ml-auto" navbar>
          <NavItem className="d-md-down-none">
            <NavLink href="#">{auth.name}</NavLink>
          </NavItem>
          <UncontrolledDropdown nav direction="down">
            <DropdownToggle nav>
              <img src={auth.image ? auth.image : sygnet} className="img-avatar rounded-0" alt={auth.email} />
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem header tag="div" className="text-center"><strong>Account</strong></DropdownItem>
              <DropdownItem><NavLink tag={Link} to="/profile"><i className="fa fa-user"></i> Profile</NavLink></DropdownItem>
              <DropdownItem onClick={this.onClickLogout}><i className="fa fa-lock"></i> Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
