export default {
  items: [
    {
      name: 'Profile',
      url: '/profile',
      icon: 'icon-user',
    },
    {
      title: true,
      name: 'Managements',
      wrapper: { // optional wrapper object
        element: '', // required valid HTML5 element tag
        attributes: {} // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: '' // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Leads',
      url: '/leads',
      icon: 'fa fa-users',
    },
    {
      name: 'Installer Profile',
      url: '/installers',
      icon: 'fa fa-cogs',
    },
  ],
};
